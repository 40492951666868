import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide} from 'vue';
import ViewFileUtil,{IViewFileDataObj} from './viewFileUtil';
export default defineComponent ({
    name: 'viewFile',
    props: {
        engineParams: {
            type: Object
        }
    },
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IViewFileDataObj=reactive<IViewFileDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            fileUrl:''
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new ViewFileUtil(proxy,dataObj);
            if(props.engineParams && props.engineParams.url){
                dataObj.fileUrl=props.engineParams.url;
            }
        })
        onMounted(()=>{})

        return{
            ...toRefs(dataObj)
        }
    }
});