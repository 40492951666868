import BaseBean from "@/utils/BaseBean";

export interface IViewFileDataObj {
    utilInst:ViewFileUtil
    refMap:Map<string,any>
    pageListRef:any
    fileUrl:string
}

export default class ViewFileUtil extends BaseBean{
    public dataObj:IViewFileDataObj

    constructor(proxy:any,dataObj:IViewFileDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
}